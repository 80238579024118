import Vue from "vue"
import App from "./App.vue"
import i18n from "./i18n"
import router from "./router"
import VeeValidate from "vee-validate" //https://vee-validate.logaretm.com/v2/examples/scopes.html,https://vee-validate.logaretm.com/v2/examples/
import axios from "axios"
import VueAxios from "vue-axios"
import Snotify, { SnotifyPosition } from "vue-snotify" //https://artemsky.github.io/vue-snotify/documentation/installation.html,https://artemsky.github.io/vue-snotify/documentation/api/options.html#setting-default-configuration
import VueLoading from "vue-loading-overlay" //https://github.com/ankurk91/vue-loading-overlay
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
import "@mdi/font/css/materialdesignicons.css"

import LoadScript from "vue-plugin-load-script" //https://www.npmjs.com/package/vue-plugin-load-script

import store from "./store" //https://www.smashingmagazine.com/2020/10/authentication-in-vue-js
import Vuetify from "vuetify"
import vuetify from "@/plugins/vuetify"
// import DaySpanVuetify from "dayspan-vuetify"; //https://github.com/ClickerMonkey/dayspan-vuetify?ref=madewithvuejs.com

import "vuetify/dist/vuetify.css"
import "material-design-icons-iconfont/dist/material-design-icons.css"
// import "dayspan-vuetify/dist/lib/dayspan-vuetify.min.css";
import VueTelInputVuetify from "vue-tel-input-vuetify"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import LazyLoad from "./directives/LazyLoad"
import "./rules" // Import your custom rules

const options = {
  toast: {
    position: SnotifyPosition.rightTop,
    closeOnClick: true,
  },
}
Vue.config.productionTip = false

// const Swal = require('sweetalert2');

Vue.use(VueLoading)
Vue.directive("lazyLoad", LazyLoad)
Vue.use(Snotify, options)
Vue.use(VeeValidate)
Vue.use(VueAxios, axios)
Vue.use(LoadScript)
Vue.use(Vuetify)
Vue.use(VeeValidate)
// Vue.use(DaySpanVuetify, {
//   methods: {
//     getDefaultEventColor: () => "#1976d2",
//   },
// });
Vue.use(VueTelInputVuetify, {
  vuetify,
})
// VeeValidate.Validator.extend("verify_password", {
//   getMessage: (field) =>
//     `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
//   validate: (value) => {
//     var strongRegex = new RegExp(
//       "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
//     );
//     return strongRegex.test(value);
//   },
// });
// VeeValidate.Validator.extend("phone", {
//   getMessage: (field) => `Should Be Phone Format`,
//   validate: (value) => {
//     // var strongRegex = new RegExp("^(\\00\\d{1,3}( )?)?(\\+\\d{1,3}( )?)?((\\(\\d{3}\\))|\\d{3})[- .]?\\d{3}[- .]?\\d{4}$");
//     var strongRegex = new RegExp("^(\\+\\d{1,3}( )?)?[0-9]{10,14}$");
//     return strongRegex.test(value);
//   },
// });

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
  vuetify: new Vuetify(),
  data() {
    return {
      isLoading: false,
    }
  },
}).$mount("#app")

router.beforeResolve((to, from, next) => {
  //https://www.digitalocean.com/community/tutorials/add-loading-indicators-to-your-vuejs-application
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    // NProgress.start();
    store.dispatch("toggleRouteLoading", true)
  }
  next()
})

router.afterEach((to, from) => {
  //https://www.digitalocean.com/community/tutorials/add-loading-indicators-to-your-vuejs-application
  // Complete the animation of the route progress bar.
  //NProgress.done()
  setTimeout(() => {
    store.dispatch("toggleRouteLoading", false)
  }, 500)
})

// inject store to access in before request interceptors
let request_store
export const injectStore = _request_store => {
  request_store = _request_store
}
injectStore(store)
axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization =
      "Bearer " + request_store.getters.StateUser?.token_data?.access_token
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function axiosRetryInterceptor(error) {
    var user = store.getters.StateUser // JSON.parse(localStorage.getItem('user'));
    const originalRequest = error.config
    if (
      originalRequest.url.indexOf("refresh_token") > -1 &&
      error.response.status == 400
    ) {
      //return Promise.reject(error);
      store.commit("removeUser")
      window.dispatchEvent(
        new CustomEvent("loginModalChange", {
          detail: {
            opened: true,
            tab: 0,
          },
        })
      )
    }
    if (error.response.status == 401) {
      var headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user?.token_data?.access_token,
          "accept-language": localStorage.lang,
          "CLIENT-ID": process.env.VUE_APP_CLIENTID,
          "Client-Secret": process.env.VUE_APP_ClientSecret,
          "USER-TIMEZONE": Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }
      return axios
        .post(
          process.env.VUE_APP_APIEndPoint + "auth/refresh_token/",
          {
            refresh_token: user?.token_data?.refresh_token,
          },
          headers
        )
        .then(res => {
          if (res.status === 200) {
            if (res.data.success) {
              store.commit("setUser", res.data)
              originalRequest.headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + res.data.token_data.access_token,
                "accept-language": localStorage.lang,
                "CLIENT-ID": process.env.VUE_APP_CLIENTID,
                "Client-Secret": process.env.VUE_APP_ClientSecret,
                "USER-TIMEZONE":
                  Intl.DateTimeFormat().resolvedOptions().timeZone,
              }

              //originalRequest.headers.HTTPUSERTIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
              return axios.request(originalRequest)
            } else {
              store.commit("removeUser")
              window.dispatchEvent(
                new CustomEvent("loginModalChange", {
                  detail: {
                    opened: true,
                    tab: 0,
                  },
                })
              )
            }
          } else {
            store.commit("removeUser")
            window.dispatchEvent(
              new CustomEvent("loginModalChange", {
                detail: {
                  opened: true,
                  tab: 0,
                },
              })
            )
          }
        })
    }
    return Promise.reject(error)
    // error.response.statusText
  }
)

$(function () {
  // let for now until asking
  // const userObj = JSON.parse(localStorage.getItem("vuex"))
  // const isOperatorOrAdmin =
  //   userObj.auth.user.user.user_type === "operator" ||
  //   userObj.auth.user.user.user_type === "institute_admin"

  // if (isOperatorOrAdmin) return
  if (
    window.location.href.includes("https://qc.alkuttab.io/") ||
    window.location.href.includes("https://preprod.alkuttab.io/") ||
    window.location.href.includes("https://alkuttab.io/")
  ) {
    var scriptContent = `
    var $zoho=$zoho || {};
    $zoho.salesiq = $zoho.salesiq || {widgetcode: "siq8a5e40545cfff40494091a12ae30ef51b0da2ab2bf599156e2960f8f5c006350", values:{},ready:function(){}};
    var d=document;
    s=d.createElement("script");
    s.type="text/javascript";
    s.id="zsiqscript";
    s.defer=true;
    s.src="https://salesiq.zohopublic.com/widget";
    t=d.getElementsByTagName("script")[0];
    t.parentNode.insertBefore(s,t);
  `

    var script = document.createElement("script")
    script.type = "text/javascript"
    script.id = "zsiqchat"
    script.innerHTML = scriptContent

    document.head.appendChild(script)
  }

  if (
    !window.location.href.includes("qc") &&
    !window.location.href.includes("preprod") &&
    !window.location.href.includes("localhost") &&
    !window.location.href.includes("/dashboard")
  ) {
    function gtag() {
      dataLayer.push(arguments)
    }

    // Tiktok
    const script = document.createElement("script")
    script.innerHTML = `!function (w, d, t) {
      w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
      ttq.load('CM3JOGBC77U0VR5LI2G0');
      ttq.page();
    }(window, document, 'ttq');`
    document.head.appendChild(script)

    // Amazon
    ;(function (w, d, x, id) {
      s = d.createElement("script")
      s.src =
        "https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js"
      s.async = 1
      s.id = id
      d.getElementsByTagName("head")[0].appendChild(s)
      w[x] =
        w[x] ||
        function () {
          ;(w[x].ac = w[x].ac || []).push(arguments)
        }
    })(
      window,
      document,
      "amazon_connect",
      "72cf69e5-1c84-4899-a356-0d4b241ab1e4"
    )
    amazon_connect("styles", {
      openChat: { color: "white", backgroundColor: "#123456" },
      closeChat: { color: "white", backgroundColor: "#123456" },
    })
    amazon_connect(
      "snippetId",
      "QVFJREFIaGdrRkFyOStHNjU4cDN3RWRrZXp6QkY2NWpiaU1JWHIybjgvQmZ6VTkyNWdGZ0pSM1VRdHZhd3VCVks0NG85dUxiQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNWEd3VVNOYUZqYlNrSU5IQ0FnRVFnQ3VtL09MekF3N1dWZU9tbUFmVkZKNXJCSEdqZFRQME1zdkFONUkvMjNGb1UxM21YeTdpalowUW9PSjI6OjdlcXdsNjlDemZFMklVV005OVNBM0s5MXYxQlpYbkN2aFdLTjZSTWNpNmhROTZCa3RQd0NnNEp2djErZVVUcWFhTEdwZnN4TlNCZVZXS3c4dVRlUWk0ZEw2eFo4bzhiRGNydDUvYnlSUEVrVkQ4bUdadHA5MkpnMVZyREFnY0Z5aWZud0JlZnhiTGUrVlI3TENMclNDZnlvTi9zN3lpWT0="
    )
    amazon_connect("supportedMessagingContentTypes", [
      "text/plain",
      "text/markdown",
    ])

    // MS calrity
    ;(function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          ;(c[a].q = c[a].q || []).push(arguments)
        }
      t = l.createElement(r)
      t.async = 1
      t.src = "https://www.clarity.ms/tag/" + i
      y = l.getElementsByTagName(r)[0]
      y.parentNode.insertBefore(t, y)
    })(window, document, "clarity", "script", "9d3dr9e9jo")

    // GTM
    ;(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      })
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : ""
      j.async = true
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
      f.parentNode.insertBefore(j, f)
    })(window, document, "script", "dataLayer", "GTM-MKR2BKW")

    window.dataLayer = window.dataLayer || []
    gtag("js", new Date())
    gtag("config", "AW-10838775712")

    // FB pixel
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments)
      }

      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = "2.0"

      n.queue = []
      t = b.createElement(e)
      t.async = !0

      t.src = v
      s = b.getElementsByTagName(e)[0]

      s.parentNode.insertBefore(t, s)
    })(
      window,
      document,
      "script",

      "https://connect.facebook.net/en_US/fbevents.js"
    )

    fbq("init", "454881212957955")

    fbq("track", "PageView")
  }
})
