var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-container"},[_c('SupportPalestine',{attrs:{"institute_name":_vm.institute_name}}),_c('nav',{staticClass:"d-flex align-items-center justify-content-between",class:_vm.navbarClass},[_c('router-link',{staticClass:"logo d-flex align-items-center text-decoration-none",attrs:{"to":{ name: 'FancyHome' }}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDashboardRoute),expression:"isDashboardRoute"}],attrs:{"color":_vm.isScrolled ? 'black' : 'white'},on:{"click":function($event){$event.preventDefault();return _vm.toggleDashboardDrawer.apply(null, arguments)}}},[_vm._v("mdi-menu")]),_c('img',{attrs:{"src":_vm.instituteLogo,"alt":"logo"}})],1),_c('v-app-bar-nav-icon',{staticClass:"drawer",class:_vm.drawing,on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('ul',{staticClass:"menu-list"},_vm._l((_vm.headerData.headerLinks),function(headerList){return _c('li',{key:headerList.id,class:_vm.navItemClass},[(
            headerList.componentName &&
            !_vm.hideForKuttab(headerList.hideForAlkuttab)
          )?_c('router-link',{class:{ disabled: headerList.isDisabled },attrs:{"to":{ name: headerList.componentName }}},[_vm._v(" "+_vm._s(_vm.$t("header." + headerList.name))+" ")]):(headerList.link)?_c('a',{attrs:{"href":headerList.link}},[_vm._v(" "+_vm._s(_vm.$t("header." + headerList.name))+" ")]):(headerList.subMenu)?_c('span',{class:_vm.subMenuNavItemClass},[_c('span',{staticClass:"submenu-nav-item-text",style:({ color: _vm.isScrolled ? 'rgb(56, 56, 56)' : '#fff' })},[_vm._v(" "+_vm._s(_vm.$t("header." + headerList.name))+" ")]),_c('v-icon',{staticClass:"submenu-nav-item-icon",attrs:{"color":_vm.isScrolled ? 'rgb(56, 56, 56)' : '#fff'}},[_vm._v("mdi-chevron-down ")])],1):_vm._e(),(headerList.subMenu && headerList.subMenu.length)?[_c('ul',{staticClass:"sub-menu-list"},_vm._l((headerList.subMenu),function(subLink){return _c('li',{key:subLink.id},[_c('router-link',{attrs:{"to":{ name: subLink.componentName }}},[_vm._v(" "+_vm._s(_vm.$t("header." + subLink.name) + _vm.instituteName)+" ")])],1)}),0)]:_vm._e()],2)}),0),_c('div',{staticClass:"actions",class:_vm.scrollActions},[_c('CurrencyList'),_c('SwitchLang',{staticClass:"lang-btn",attrs:{"isScrolled":_vm.isScrolled}}),(_vm.state.isAuthenticated)?[_c('button',{staticClass:"user-menu position-relative"},[_c('v-icon',{attrs:{"color":_vm.isScrolled ? 'rgb(56, 56, 56)' : '#fff'}},[_vm._v("mdi-account ")]),_c('span',{staticClass:"full-name"},[_vm._v(" "+_vm._s(_vm.fullname)+" ")]),_c('v-icon',{attrs:{"color":_vm.isScrolled ? 'rgb(56, 56, 56)' : '#fff'}},[_vm._v("mdi-chevron-down ")]),_c('ul',{staticClass:"user-sub-menu"},[_vm._l((_vm.headerData.header_menu[
                _vm.state.StateUser.user.user_type.includes('student')
                  ? 'student'
                  : _vm.state.StateUser.user.user_type
              ]),function(menuLink){return _c('li',{key:menuLink.id},[_c('router-link',{attrs:{"to":{ name: menuLink.componentName }}},[_vm._v(_vm._s(_vm.$t("header." + menuLink.name)))])],1)}),_c('li',[_c('Logout',{staticClass:"logout-btn"})],1)],2)],1)]:[_c('v-btn',{staticClass:"login-btn",attrs:{"id":"login-btn"},on:{"click":_vm.openLoginModal}},[_vm._v(" "+_vm._s(_vm.$t("header.login"))+" ")]),_c('v-btn',{staticClass:"register-btn",attrs:{"id":"register-btn"},on:{"click":_vm.openRegisterModal}},[_vm._v(" "+_vm._s(_vm.$t("header.signUpForFree"))+" ")])]],2),_c('v-navigation-drawer',{attrs:{"app":"","fixed":"","temporary":"","overlay-color":"#000","overlay-opacity":"0.7","disable-resize-watcher":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',[(!_vm.state.isAuthenticated)?[_c('v-list-item',[_c('v-btn',{staticClass:"login-btn",attrs:{"id":"login-btn"},on:{"click":_vm.openLoginModal}},[_vm._v(" "+_vm._s(_vm.$t("header.login"))+" ")])],1),_c('hr')]:[_c('div',[_c('v-expansion-panels',{attrs:{"accordion":"","flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.fullname)+" ")]),_vm._l((_vm.headerData.header_menu[
                      _vm.state.StateUser.user.user_type
                    ]),function(menu_list){return _c('v-expansion-panel-content',{key:menu_list.id},[_c('router-link',{attrs:{"to":{ name: menu_list.componentName }}},[_vm._v(" "+_vm._s(_vm.$t("header." + menu_list.name))+" ")])],1)}),_c('v-expansion-panel-content',[_c('Logout',{staticClass:"logout-btn"})],1)],2)],1)],1)],_vm._l((_vm.headerData.headerLinks),function(headerList){return _c('div',{key:headerList.id},[(headerList.subMenu && headerList.subMenu.length)?_c('v-expansion-panels',{attrs:{"accordion":"","flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$t("header." + headerList.name))+" ")]),_vm._l((headerList.subMenu),function(subMenuLink){return _c('v-expansion-panel-content',{key:subMenuLink.id},[_c('router-link',{attrs:{"to":{ name: subMenuLink.componentName }}},[_vm._v(" "+_vm._s(_vm.$t("header." + subMenuLink.name) + _vm.instituteName)+" ")])],1)})],2)],1):_vm._e(),(!_vm.hideForKuttab(headerList.hideForAlkuttab))?_c('v-list-item',{staticClass:"drawer-list-item"},[(headerList.componentName)?_c('router-link',{class:{ disabled: headerList.isDisabled },attrs:{"to":{ name: headerList.componentName }}},[_vm._v(" "+_vm._s(_vm.$t("header." + headerList.name))+" ")]):(headerList.link)?_c('a',{attrs:{"href":headerList.link}},[_vm._v(" "+_vm._s(_vm.$t("header." + headerList.name))+" ")]):_vm._e()],1):_vm._e()],1)}),_c('v-list-item',{staticClass:"d-flex justify-content-between"},[_c('SwitchLang',{attrs:{"drawer":true}})],1)],2)],1)],1),_c('LoginModal')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }